import { createSlice } from "@reduxjs/toolkit";

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState: {
    value: "",
    showLoader: false,
    userdetail: {},
    userMenus: [],
    userImage: null,
    userRoles: [],
    activeGridTabInd: 0,
    badgeValue: 0,
    locationId: null,
    calendarEvents: [],
    isLegacyMember:null,
    eventSuccess: false,
    role: null,
    profilePicUrl: null,
  },
  reducers: {
    showLoader: (state) => {
      state.showLoader = true;
    },
    hideLoader: (state) => {
      state.showLoader = false;
    },
    setUserDetail: (state, action) => {
      state.userdetail = action.payload;
    },
    setProfilePic: (state, action) => {
      state.profilePicUrl = action.payload;
    },
    setCalendarEvents: (state, action) => {
      state.calendarEvents = action.payload;
    },
    setLocation_id: (state, action) => {
      state.locationId = action.payload;
    },
    setIsLegacyMember: (state, action) => {
      state.isLegacyMember = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    clearLocation_id: (state, action) => {
      state.locationId = null;
    },
    setEventSuccess: (state, action) => {
      state.eventSuccess = action.payload;
    },
    clearEventSuccess: (state, action) => {
      state.eventSuccess = null;
    },
    
    clearUserDetail: (state, action) => {
      state.userdetail = {};
    },
    
    clearCalendarEvents: (state, action) => {
      state.calendarEvents = [];
    },
    setUserImage: (state, action) => {
      state.userImage = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRoles = action.payload;
    },
    clearUserRole: (state, action) => {
      state.userRoles = [];
    },
    setUserMenus: (state, action) => {
      state.userMenus = action.payload;
    },
    clearUserMenus: (state, action) => {
      state.userMenus = [];
    },
    setActiveGridTabInd: (state, action) => {
      state.activeGridTabInd = action.payload;
    },
    setBadgeValue: (state, action) => {
      state.badgeValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showLoader,
  hideLoader,
  setUserDetail,
  clearUserDetail,
  setUserImage,
  userImage,
  setUserRole,
  clearUserRole,
  setUserMenus,
  clearUserMenus,
  setActiveGridTabInd,
  setBadgeValue,
  setLocation_id,
  clearLocation_id,
  setCalendarEvents,
  clearCalendarEvents,
  setEventSuccess,
  clearEventSuccess,
  setIsLegacyMember,
  setRole,
  setProfilePic
} = mainSlice.actions;

export default mainSlice.reducer;
